var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card-title',[_vm._v(" Campus Management Report "),_c('v-spacer')],1),_c('v-form',{ref:"form"},[_c('v-card-text',[_c('v-row',[(_vm.allowRegionFilter)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.regions,"item-text":"Name","item-value":"RegionID","label":"Region","prepend-icon":"mdi-earth","clearable":""},on:{"change":function($event){_vm.selectCentre = null}},model:{value:(_vm.selectRegion),callback:function ($$v) {_vm.selectRegion=$$v},expression:"selectRegion"}})],1):_vm._e(),(_vm.allowCentreFilter)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.filteredCentres,"item-text":"Name","item-value":"CentreID","label":"Campus","prepend-icon":"mdi-home-outline","clearable":""},model:{value:(_vm.selectCentre),callback:function ($$v) {_vm.selectCentre=$$v},expression:"selectCentre"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{ref:"fromdate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"From date submitted","hint":"DD/MM/YYYY format","persistent-hint":"","prepend-icon":"mdi-calendar","clearable":""},on:{"blur":function($event){_vm.fromdate = _vm.parseDate(_vm.fromDateFormatted)}},model:{value:(_vm.fromDateFormatted),callback:function ($$v) {_vm.fromDateFormatted=$$v},expression:"fromDateFormatted"}},on))]}}]),model:{value:(_vm.menuFromDate),callback:function ($$v) {_vm.menuFromDate=$$v},expression:"menuFromDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuFromDate = false}},model:{value:(_vm.fromdate),callback:function ($$v) {_vm.fromdate=$$v},expression:"fromdate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{ref:"todate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"To date submitted","hint":"DD/MM/YYYY format","persistent-hint":"","prepend-icon":"mdi-calendar","clearable":""},on:{"blur":function($event){_vm.todate = _vm.parseDate(_vm.toDateFormatted)}},model:{value:(_vm.toDateFormatted),callback:function ($$v) {_vm.toDateFormatted=$$v},expression:"toDateFormatted"}},on))]}}]),model:{value:(_vm.menuToDate),callback:function ($$v) {_vm.menuToDate=$$v},expression:"menuToDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuToDate = false}},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}})],1)],1)],1),_c('v-row',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","loading":_vm.searchLoading},on:{"click":_vm.Search}},[_vm._v("Search")])],1)],1)],1)],1),(_vm.showTable)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Results "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.fromDateFormatted)+" "+_vm._s(_vm.toDateFormatted)+" ")]),_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.results,"item-key":"name","search":_vm.search,"custom-filter":_vm.customFilter,"loading":_vm.searchLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(header,i){return _c('td',{key:i},[(header.value == 'RegionName')?_c('div',[_c('p',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("Total")])]):_vm._e(),(header.value == 'HomeworkCount')?_c('div',[_c('p',{staticClass:"subtitle-1 text-center font-weight-bold"},[_vm._v(_vm._s(_vm.totals && _vm.totals.HomeworkCountTotal))])]):_vm._e(),(header.value == 'MarkedCount')?_c('div',[_c('p',{staticClass:"subtitle-1 text-center font-weight-bold"},[_vm._v(_vm._s(_vm.totals && _vm.totals.MarkedCountTotal))])]):_vm._e(),(header.value == 'NotMarkedCount')?_c('div',[_c('p',{staticClass:"subtitle-1 text-center font-weight-bold"},[_vm._v(_vm._s(_vm.totals && _vm.totals.NotMarkedCountTotal))])]):_vm._e(),(header.value == 'AwardCount')?_c('div',[_c('p',{staticClass:"subtitle-1 text-center font-weight-bold"},[_vm._v(_vm._s(_vm.totals && _vm.totals.AwardCountTotal))])]):_vm._e()])}),0)]}},{key:"item.DateSubmitted",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.DateSubmitted)))])]}}],null,true)})],1)],1):_vm._e()],1),_c('div',{staticClass:"text-center"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }